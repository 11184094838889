// Import english translations
import enCommon from '@/locales/en/common.json';
import enGenerateKey from '@/locales/en/generateKey.json';
import enHome from '@/locales/en/home.json';
import enProfile from '@/locales/en/profile.json';
import enSecrets from '@/locales/en/secrets.json';
import enGuardianDetail from '@/locales/en/guardianDetail.json';
import enStatuses from '@/locales/en/statuses.json';
import enVerifyAccount from '@/locales/en/verifyAccount.json';
import enNotifications from '@/locales/en/notifications.json';
import enMnemonicPhraseMessages from '@/locales/en/mnemonicPhraseMessages.json';
// Import spanish translations
import esCommon from '@/locales/es/common.json';
import esGenerateKey from '@/locales/es/generateKey.json';
import esHome from '@/locales/es/home.json';
import esProfile from '@/locales/es/profile.json';
import esSecrets from '@/locales/es/secrets.json';
import esGuardianDetail from '@/locales/es/guardianDetail.json';
import esStatuses from '@/locales/es/statuses.json';
import esVerifyAccount from '@/locales/es/verifyAccount.json';
import esNotifications from '@/locales/es/notifications.json';
import esMnemonicPhraseMessages from '@/locales/es/mnemonicPhraseMessages.json';
// Import xx translations
import xxCommon from '@/locales/xx/common.json';
import xxGenerateKey from '@/locales/xx/generateKey.json';
import xxHome from '@/locales/xx/home.json';
import xxProfile from '@/locales/xx/profile.json';
import xxSecrets from '@/locales/xx/secrets.json';
import xxGuardianDetail from '@/locales/xx/guardianDetail.json';
import xxStatuses from '@/locales/xx/statuses.json';
import xxVerifyAccount from '@/locales/xx/verifyAccount.json';
import xxNotifications from '@/locales/xx/notifications.json';
import xxMnemonicPhraseMessages from '@/locales/xx/mnemonicPhraseMessages.json';

const enMessages = {
    ...enCommon,
    ...enGenerateKey,
    ...enHome,
    ...enProfile,
    ...enSecrets,
    ...enGuardianDetail,
    ...enVerifyAccount,
    ...enStatuses,
    ...enNotifications,
    ...enMnemonicPhraseMessages
};

const esMessages = {
    ...esCommon,
    ...esGenerateKey,
    ...esHome,
    ...esProfile,
    ...esSecrets,
    ...esGuardianDetail,
    ...esVerifyAccount,
    ...esStatuses,
    ...esNotifications,
    ...esMnemonicPhraseMessages
};

const xxMessages = {
    ...xxCommon,
    ...xxGenerateKey,
    ...xxHome,
    ...xxProfile,
    ...xxSecrets,
    ...xxGuardianDetail,
    ...xxVerifyAccount,
    ...xxStatuses,
    ...xxNotifications,
    ...xxMnemonicPhraseMessages
};

export default defineI18nConfig(() => ({
    legacy: false,
    locale: 'en',
    messages: {
        en: enMessages,
        es: esMessages,
        xx: xxMessages
    }
}))

